export const MENUITEMS = [
  {
    menutitle: "Main Menus",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Dashboard",
        icon: "dashboard",
        type: "link",
        active: true,
        path: `/dashboard`,
      },
      {
        title: "Orders",
        icon: "order",
        type: "link",
        active: false,
        path: `/orders`,
      },
      {
        title: "Products",
        icon: "product",
        // type: 'link',
        active: false,
        // path: `/products`
        type: "sub",
        path: ``,
        badge: "badge badge-light-danger",
        // badgetxt: "6",
        // roles: ['admin'] ,
        children: [
          { path: `/products`, title: "Products", type: "link" },
          { path: `/brands`, title: "Brands", type: "link" },
          { path: `/collections`, title: "Collections", type: "link" },
          { path: `/sub-collections`, title: "Sub-Collections", type: "link" },
          { path: `/variants`, title: "Variants", type: "link" },

          // { path: `/items`, title: "Items", type: "link" },
          // {
          //   path: `/products`, title: "Products", type: "link"
          // },
          // { path: `/stocks`, title: "Stocks", type: "link" },
        ],
        roles: ["admin"],
      },
      {
        title: "Products",
        icon: "product",
        // type: 'link',
        active: false,
        // path: `/products`
        type: "sub",
        path: ``,
        badge: "badge badge-light-danger",
        // badgetxt: "6",
        // roles: ['admin'] ,
        children: [
          { path: `/products`, title: "Products", type: "link" },
          { path: `/brands`, title: "Brands", type: "link" },
          { path: `/collections`, title: "Collections", type: "link" },
          { path: `/sub-collections`, title: "Sub-Collections", type: "link" },
          { path: `/variants`, title: "Variants", type: "link" },

          // { path: `/items`, title: "Items", type: "link" },
          // {
          //   path: `/products`, title: "Products", type: "link"
          // },
          // { path: `/stocks`, title: "Stocks", type: "link" },
        ],
        roles: ["store"],
      },
      // {
      //   title: 'Variants',
      //   icon: 'variant',
      //   type: 'link',
      //   active: false,
      //   path: `/variants`
      // },
      // {
      //   title: 'Brands',
      //   icon: 'brand',
      //   type: 'link',
      //   active: false,
      //   path: `/brands`
      // },
      {
        title: "Transactions",
        icon: "transaction",
        type: "link",
        active: false,
        path: `/transactions`,
      },
      {
        title: "Stores",
        icon: "store",
        type: "link",
        path: `/stores`,
        badge: "badge badge-light-danger",
        // badgetxt: "6",
        active: false,
        roles: ["admin"],
        // children: [
        //   { path: `/store`, title: "Stores", type: "link" },
        //   { path: `/collections`, title: "Collections", type: "link" },
        //   { path: `/subcollections`, title: "Sub Collections", type: "link" },
        // ],
      },
      {
        title: "My Store",
        icon: "store",
        type: "link",
        path: `/store`,
        badge: "badge badge-light-danger",
        active: false,
        roles: ["store"],
      },
      {
        title: "Coupons",
        icon: "store",
        type: "link",
        path: `/coupons`,
        badge: "badge badge-light-danger",
        active: false,
        // roles: ['admin'],
      },
      {
        title: "Reports",
        icon: "report",
        type: "link",
        path: `/reports`,
        badge: "badge badge-light-danger",
        active: false,
        // roles: ['admin'] ,
      },
      // {
      //   title: 'Statistics',
      //   icon: 'statistic',
      //   type: 'link',
      //   active: false,
      //   path: `/statistics`
      // },
      {
        title: "Customers",
        icon: "customer",
        type: "link",
        active: false,
        path: `/customers`,
        roles: ["admin"],
      },
      {
        title: "Role Management",
        icon: "role",
        type: "link",
        active: false,
        path: `/role-management`,
        roles: ["admin"],
      },
      {
        title: "Role Management",
        icon: "role",
        type: "link",
        active: false,
        path: `/role-management`,
        roles: ["store"],
      },
      {
        title: "CMS",
        icon: "role",
        type: "link",
        active: false,
        path: `/cms`,
        roles: ["admin"],
      },
      // {
      //   title: 'Log Activity',
      //   icon: 'log',
      //   type: 'link',
      //   active: false,
      //   path: `/activity`
      // },
    ],
  },
  // {
  //   menutitle: 'Support',
  //   menucontent: 'Dashboards,Widgets',
  //   Items: [
  //     {
  //       title: 'Settings',
  //       icon: 'setting',
  //       type: 'link',
  //       active: true,
  //       path: `/setting`
  //     },
  //     // {
  //     //   title: 'Help',
  //     //   icon: 'help',
  //     //   type: 'link',
  //     //   active: false,
  //     //   path: `/help`
  //     // },
  //   ],
  // },
];
